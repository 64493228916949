import React from 'react'
import { useState } from "react";
import Swal from 'sweetalert2';


function Career() {
    const [jobRole, setJobRole] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [details, setDetails] = useState("");
    const [preferredLocation, setPreferredLocation] = useState("");
    const [base64Attachment, setBase64Attachment] = useState("");
    const [selectedFile, setSelectedFiles] = useState([]);

    const [btnDisabled, setBtnDisabled] = useState(false);
    const [btnText, setBtnText] = useState("Submit");

    

    const onChangeFile = (e) =>{
        const files = e.target.files;
        const file = files[0];

        setSelectedFiles(files);



        getBase64File(file);
    }

    const getBase64File = (file) =>{
        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () =>{
            setBase64Attachment(reader.result);
        }
    }

    const submitJobMailClick = (e) =>{
        e.preventDefault();

        //console.log(selectedFile)

        let attachFileName = "";

        if(selectedFile.length>0)
        {
            let tempFile = selectedFile[0];

            if(tempFile.type!=="application/pdf")
            {
                Swal.fire("Warning", "Please attach resume in pdf format.", "warning");
                return false;
            }

            attachFileName = tempFile.name;
        }

        setBtnDisabled(true)
        setBtnText("Submitting...")
        //console.log(preferredLocation)
        //return false;
        

        fetch('https://qnoovrz8q6.execute-api.ap-south-1.amazonaws.com/sendResumeMail', {
            mode:"no-cors",
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                 "first_name": firstName,
                 "last_name": lastName,
                 "user_email": userEmail,
                 "user_contact": contactNo,
                 "user_message": details,
                 "base64File":base64Attachment,
                 "FileName":attachFileName,
                 "job_role": jobRole,
                 'preferredLocation':preferredLocation,

            })
            }).then( response => {

                Swal.fire("Success", "Mail Sent Successfully", "success");
                setBtnDisabled(false)
                setBtnText("Send")
                
            })
            .catch(error => {
                setBtnDisabled(false)
                setBtnText("Send")
                Swal.fire("Error", "Error sending mail.", "error");
            });
        
        
        e.target.reset();
    }

  return (
    <>
        <div className='container-fluid'>
            <div className="modal fade" id="jobApplyModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="">Apply Job</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={submitJobMailClick} enctype="multipart/form-data" method="post">
                                <div className="mb-3 mt-3">
                                    <h5 for="txtRole" className="form-label"><small>Job Role: </small> {jobRole}</h5>
                                    
                                </div>
                            
                                <div class="row">
                                    <div class="col mb-3">
                                        <label for="txtFName" className="form-label">First Name <span className='text-danger'>*</span></label>
                                        <input type="text" className="form-control" id="txtFName" placeholder="First Name" name="txtFName" 
                                        onChange={(e)=>{
                                            setFirstName(e.target.value);
                                        }}
                                        required/>
                                    </div>
                                    <div class="col mb-3 ">

                                        <label for="txtLName" className="form-label">Last Name <span className='text-danger'>*</span></label>
                                        <input type="text" className="form-control" id="txtLName" placeholder="Last Name" 
                                        name="txtLName" 
                                        onChange={(e)=>{
                                            setLastName(e.target.value);
                                        }}
                                        required/>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <label for="txtEmail" className="form-label">Email <span className='text-danger'>*</span></label>
                                        <input type="email" className="form-control" id="txtEmail" placeholder="Email" name="txtEmail" 
                                        onChange={(e)=>{
                                            setUserEmail(e.target.value);
                                        }}
                                        required/>
                                    </div>
                                    <div class="col">

                                        <label for="txtContactNo" className="form-label">Contact No <span className='text-danger'>*</span></label>
                                        <input type="text" className="form-control" id="txtContactNo" placeholder="Contact No." name="txtContactNo" 
                                        onChange={(e)=>{
                                            setContactNo(e.target.value);
                                        }}
                                        required/>
                                    </div>
                                </div>
                                
                                
                                <div className="mb-3 mt-3">
                                    <label for="txtComment" className="form-label">Details <span className='text-danger'>*</span></label>
                                    <textarea className="form-control" placeholder='Enter Details' rows="5" id='txtComment'
                                    onChange={(e)=>{
                                        setDetails(e.target.value);
                                    }}
                                    required
                                    ></textarea>
                                </div>
                                
                                <div class="row">
                                    <div className="col-md-7 mb-3">
                                        <label for="formFile" className="form-label">Resume <span className='text-danger'>*</span></label>
                                        <input className="form-control" type="file" id="formFile"
                                        onChange={onChangeFile}
                                        accept="application/pdf"
                                        required
                                        />
                                    </div>
                                    <div class="col-md-5 mb-3 ">

                                        <label for="txtLName" className="form-label">Preferred Location <span className='text-danger'>*</span></label>
                                        <select className='form-select' required
                                        onChange={(e)=>{
                                            setPreferredLocation(e.target.value)
                                        }}
                                        
                                        >
                                            <option value={""}>Select Location</option>
                                            <option value={"USA (Milpitas)"}>USA (Milpitas)</option>
                                            <option value={"India (Bhubaneswar)"}>India (Bhubaneswar)</option>
                                            <option value={"Philippines (Manila)"}>Philippines (Manila)</option>
                                            <option value={"Singapore"}>Singapore</option>
                                            <option value={"Malaysia (Pinang)"}>Malaysia (Pinang)</option>
                                        </select>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-4 offset-md-4'>
                                        <button type="submit" className="btn btn-block btn-success" disabled={btnDisabled}>{btnText}</button>
                                    </div>
                                </div>      
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-12 Career-Banner text-white'>
                    <div className='inner-heading'>
                        <h1 className='text-uppercase display-6'>Careers</h1>
                        {/*<p className='blockquote'>WANT TO BE THE MARQUEE PLAYER OF A CHAMPION TEAM</p>
                        <p className='blockquote'>BE A MARQUEE-ian</p>*/}
                        <p className='blockquote'>Start your career with Marquee Semiconductor</p>
                    </div>                    
                </div>
            </div>
        </div>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12 mt-5 text-center'>
                    <h3 className='text-uppercase text-mcolor'>Careers @ Marquee</h3>
                    <p>Making A Career at Marquee Semiconductor to Explore the World of Possibilities</p>
                </div>
            </div>
            <div className='row '>
                <div className='col-md-12 mt-3'>
                    
                    {/*<ul className='whymarquee-content'>
                        <li>Marquee Semiconductor is an end-to-end SoC solution provider – specializing in current and upcoming technologies in Storage, IoT, PMIC, AI/ML and more. In order to meet our current and future needs, we are looking for self-motivated, self-driven and pro-active engineers. </li>
                        <li>We provide intern-cum-job offer to the fresher world of brains to provide a better foundation and live project experience. The excellence career is achieved when the opportunity matches to your field of interest. Marquee Semiconductor emphasises that to make its workspace tecno-friendly.</li>
                        <li>Also, we are looking for engineers with experience in different domain of analog and digital, who can challenge themselves, quickly learn new technologies, work with our customers in defining and implementing the next generation SoCs. Choose Marquee Semiconductor as the ladder for tomorrow’s leader and be the true leader of your domain of expertise.</li>
                        <li>If you would consider yourself to be technically challenged in a thriving engineering environment that brings out innovative solutions to its customers, just send your updated resume to <strong>resume@marqueesemi.com.</strong> </li>
                    </ul>*/}
                    <p>
                        If you are looking to challenge yourself in a thriving engineering environment that brings out the best in you, just send your updated resume to <strong className='text-primary'><u>resume@marqueesemi.com.</u></strong> 
                    </p>
                    <p> From fresh graduate to seasoned engineers, there is an opportunity that awaits!</p>
                </div>
                {/*<div className='col-md-4 d-flex align-items-center justify-content-center'>
                    <img src='../images/Career-img.jpg' alt='Marquee Simiconductor' className='img-fluid p-5'/>
                </div>
                */}
            </div>
            <div className='row mb-3'>
                <div className='col-md-12 text-center mt-3'>
                    <h4>DEBUG THE PAST, VERIFY THE PRESENT & EXECUTE THE FUTURE</h4>
                </div>
            </div>
            {/*
            <div className='row mb-3'>
                <div className='col-md-12'>
                    <h5 className='text-uppercase text-mcolor'>Profile for Internship</h5>
                </div>
                <div className='col-md-12'>
                    <p className='text-justify'>Everything has a start point. We provide opportunities to everyone and but who is really differentiating himself and/or herself with originality and thinking process will be the part of our family. The world class global mentorship and unique in-house training methodology will set a new threshold for your learning curve.</p>
                    <p>The face-to-face discussion with mentors  and/or trainers, and worldwide common training inherits the ability to handle global work culture from the beginning. </p>
                    <p>We follow a standard selection process.</p>
                    <ul>
                        <li>Test-1: Online and/or offline objective Test (Technical section + Aptitude Section)</li>
                        <li>Test-2: Problem Solving Test (Technical)</li>
                        <li>1:1 Technical Interview</li>
                        <li>HR Interview</li>
                    </ul>
                </div>
                <div className='col-md-12'>
                    <div className='alert alert-info'>
                        <p><strong>Note:- </strong>Candidates can apply directly by adding the details. Training institutes and universities are requested to connect with our HR for the on-campus and/or off-campus hiring and schedule.</p>

                    </div>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-md-12'>
                    <h5 className='text-uppercase text-mcolor'>Experienced</h5>
                </div>
                <div className='col-md-12'>
                    <p>We are always open to hire self-driven and self-motivated, open to handle challenges and having the mindset to compete with self to do better yesterday. We have customers from different regions of the world and executes dynamic projects.</p>
                    <p>You have to update yourself with new technologies to drive the cutting-edge design areas. Innovativeness and automating the process will make you productive.</p>
                    <p>Please find the job description and positions across the domains and apply for the role where you are matching.</p>
                </div>
            </div> */}
            {/* <div className='row'>
                <ul className="nav career-menu d-flex align-items-center justify-content-center" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" data-bs-toggle="pill" href="#home">Digital</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="pill" href="#menu1">Analog</a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="pill" href="#menu2">Software</a>
                    </li>
                </ul>
                <div className="tab-content container">
                    <div id="home" className="container tab-pane active"><br/>
                    <div className='row'>
                        <div className='col-md-12 mt-5 mb-5 text-center'>
                            <h3 className='text-uppercase text-mcolor'>DIGITAL BUSINESS UNIT</h3>
                            <p>Marquee Semiconductor is a “Spec to FPGA/ACAP/Silicon Solution” </p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>                            
                            <div className='card p-3'>
                                <h5>RTL Designer </h5>                             
                                <p><span className='text-danger'><i class="bi bi-geo-alt-fill"></i></span> India (Bhubaneswar), Philippines(Manila), Singapore and USA(Milpitas)</p>
                                <p><span className='text-success'><i class="bi bi-mortarboard-fill"></i></span> BE/ BTech/ MTech/MS/ PhD</p>
                                <p><span className='text-primary'><i class="bi bi-cpu-fill"></i></span> Electronics, Electrical, Computer Engineering or Computer Science Engineering</p>
                                <p><span className='text-info'><i class="bi bi-briefcase-fill"></i></span> 2 – 10 Years</p>
                                <p className='text-uppercase blockquote text-mcolor'>Job Description </p>
                                <ul>
                                    <li>Highly motivated and driven to face the challenging design problems</li>
                                    <li>Architecture and Micro-architecture of complex digital SoCs and mixed signals SoCs</li>
                                    <li>Be able to communicate with system architects and decipher system requirements and develop architectural specification of the SoC</li>
                                    <li>Understanding the future market requirements and develop micro-architecture for the silicon to serve multiple generations of market is a big plus</li>
                                    <li>Be able to micro-architect the chip considering low power and high-performance techniques</li>
                                    <li>Complete sign-off GDSII working with DV, DFT and PD engineering teams.</li>
                                </ul>
                                <p className='text-uppercase blockquote text-mcolor'>Preferred expertise </p>
                                <ul>
                                    <li>Architecting, micro-architecting and designing SOC for embedded processors for storage, IoT, security and compute applications</li>
                                    <li>Functional safety, power management, coherency, security</li>
                                    <li>Protocol knowledge – PCIe, NVMe, USB4.0, DDR, AXI, CHI, AHB, MIPI, Display port, Ethernet</li>
                                    <li>Design Techniques – Low-power, UPF, Clock Domain Crossing (CDC), Booting/Reset Flow</li>
                                    <li>SW and/or hardware validation</li>
                                    <li>Scripting Languages – Shell scripting, Python, TCL, PERL etc.</li>
                                </ul>   
                            </div>
                        </div>
                        <div className='col-md-6'>                            
                            <div className='card p-3'>
                                <h5>Design Verification (DV) Engineer </h5>                             
                                <p><span className='text-danger'><i class="bi bi-geo-alt-fill"></i></span> India (Bhubaneswar), Philippines(Manila), Singapore and USA(Milpitas)</p>
                                <p><span className='text-success'><i class="bi bi-mortarboard-fill"></i></span> BE/ BTech/ MTech/MS/ PhD</p>
                                <p><span className='text-primary'><i class="bi bi-cpu-fill"></i></span> Electronics, Electrical, Computer Engineering or Computer Science Engineering</p>
                                <p><span className='text-info'><i class="bi bi-briefcase-fill"></i></span> 2 – 10 Years</p>
                                <p className='text-uppercase blockquote text-mcolor'>Job Description </p>
                                <ul>
                                    <li>Highly motivated and driven to face the challenging SoC verification</li>
                                    <li>Development of Complex Pre-Silicon Verification environments for simulation and Emulation platform</li>
                                    <li>Developing test cases from scratch, coverage plan,</li>
                                    <li>Work directly with hardware architects, logic designers, IP providers, Physical implementers and Platform validators to influence overall SoC and system design</li>
                                    <li>Developing System C/ C++ models</li>
                                </ul>
                                <p className='text-uppercase blockquote text-mcolor'>Preferred expertise </p>
                                <ul>
                                    <li>Verifying SOC for embedded processors for storage, IoT, security and compute applications</li>
                                    <li>Functional safety, power management, coherency, security</li>
                                    <li>IP/SoC Verification, ASIC Verification, CPU (Multiple) Verification</li>
                                    <li>Formal Verification</li>
                                    <li>Protocol knowledge – PCIe, NVMe, USB4.0, DDR, AXI, CHI, AHB, MIPI, Display port, Ethernet</li>
                                    <li>Verification for CDC, Power, Booting/Reset</li>
                                    <li>SystemVerilog/UVM environment</li>
                                    <li>Scripting Languages – Shell scripting, Python, TCL, PERL etc.</li>
                                </ul>   
                            </div>
                        </div>
                       </div>
                    </div>
                    <div id="menu1" className="container tab-pane fade"><br/>
                    <h3>Menu 1</h3>
                    <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                    <div id="menu2" className="container tab-pane fade"><br/>
                    <h3>Menu 2</h3>
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
                    </div>
                </div>
            </div> */}
            <div className='row mb-5'>
                <div className='col-md-12 text-center'>
                    <h4 className='text-uppercase text-mcolor p-3 pb-0' >Grow With Us </h4><hr className='p-0 m-0'/>
                </div>
                <div className='col-md-12 mt-5'>      
                              
                    <div className="d-md-flex align-items-start">
                        <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">          
                            <button className="nav-link careermenu" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-intern" type="button" role="tab" aria-controls="v-pills-intern" aria-selected="true">Internship</button>                  
                            <button className="nav-link careermenu active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Digital BU</button>
                            <button className="nav-link careermenu" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Analog BU</button>
                            <button className="nav-link careermenu" id="v-pills-disabled-tab" data-bs-toggle="pill" data-bs-target="#v-pills-disabled" type="button" role="tab" aria-controls="v-pills-disabled" aria-selected="false">Software BU</button>
                            <button className="nav-link careermenu" id="v-pills-operation-tab" data-bs-toggle="pill" data-bs-target="#v-pills-operation" type="button" role="tab" aria-controls="v-pills-disabled" aria-selected="false">Operational Excellence Unit</button>
                        </div>
                        <div className="tab-content job-content ps-3" id="v-pills-tabContent">
                            <div className="tab-pane fade show" id="v-pills-intern" role="tabpanel" aria-labelledby="v-pills-intern-tab" tabindex="0">
                                <div className='row p-5'>
                                   {/*} <div className='col-md-12'>
                                        <p className='text-justify'>
                                        Everything has a start point. We provide opportunities to everyone especially those who are eager to level up themselves.
                                        </p>
                                        <p className='text-justify'>The world class global mentorship and unique in-house training methodology will set a new threshold for your learning curve.</p>
                                        <p>The face-to-face discussion with mentors  and/or trainers, and worldwide common training inherits the ability to handle global work culture from the beginning. </p>
                                        <p>We follow a standard selection process.</p>
                                        <ul>
                                            <li>Test-1: Online and/or offline objective Test (Technical section + Aptitude Section)</li>
                                            <li>Test-2: Problem Solving Test (Technical)</li>
                                            <li>1:1 Technical Interview</li>
                                            <li>HR Interview</li>
                                        </ul>
                                    </div>
                                    <div className='col-md-12 text-center mt-3'>
                                        <button type="button" className="btn btn-sm btn-success" data-bs-toggle="modal" data-bs-target="#jobApplyModal"
                                                onClick={(e)=>{
                                                    setJobRole("Intern");
                                                }}
                                                >Apply Now</button>
                                    </div>

                                            */}

                                    <div className='col-md-12'>
                                        <p>There are currently no open positions for internship</p>
                                        <p>Be up to date with our job opportunities thru our social media channels.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabindex="0">
                                <div className='row'>
                                <div className='col-md-6'>                            
                                        <div className='card p-3  mb-3'>
                                            <h5>SoC Architect - High Speed Interface Specialist</h5>                             
                                            <p><span className='text-danger'><i class="bi bi-geo-alt-fill"></i></span> India (Bhubaneswar), Philippines (Manila), Singapore, Malaysia</p>
                                            <p><span className='text-success'><i class="bi bi-mortarboard-fill"></i></span> BE/ BTech/ MTech/MS/ PhD</p>
                                            <p><span className='text-primary'><i class="bi bi-cpu-fill"></i></span> Electronics, Electrical, Computer Engineering or Computer Science Engineering</p>
                                            <p><span className='text-info'><i class="bi bi-briefcase-fill"></i></span> 10 – 25 Years</p>
                                            <span className='career-jd'>
                                                <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                                    <button type="button" className="btn btn-sm btn-marquee" data-bs-toggle="modal" data-bs-target="#DigitalJD1">Job Description</button>
                                                            <div className="modal fade" id="DigitalJD1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Job Description</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <h5 className='text-mcolor'>Job Summary</h5>
                                                                        <p>We are seeking an experienced SoC Architect with deep expertise in high-speed interface technologies including PCIe, CXL, and Network-on-Chip (NoC) architectures. The ideal candidate will lead the architectural definition, implementation strategy, and technical direction for next-generation semiconductor products, ensuring optimal system performance, power efficiency, and functionality. </p>
                                                                        <h5 className='text-mcolor'>Key Responsibilities</h5>
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'>
                                                                                <i className='bi bi-cpu-fill'></i>
                                                                                <p>Define and develop SoC architecture specifications with a focus on advanced interface technologies (PCIe Gen 5/6, CXL 2.0/3.0, custom NoC solutions)</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i> <p>Create detailed micro-architectural specifications including block diagrams, interface definitions, and performance models </p></li>
                                                                            <li className='list-group-item'>
                                                                                <i className='bi bi-cpu-fill'></i>
                                                                                <p>Collaborate with IP teams to define and implement PCIe controllers, CXL interfaces, and custom NoC topologies </p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i><p>Develop performance models and conduct analysis to optimize system throughput, latency, and power consumption </p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i><p>Lead technical discussions with cross-functional teams including RTL designers, verification engineers, and physical design teams </p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i><p>Identify architectural risks and develop mitigation strategies throughout the design lifecycle </p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i><p>Evaluate and select third-party IP components when appropriate </p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i><p>Participate in industry standard committees and stay current with emerging interface technologies </p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i><p>Mentor junior architects and technical staff</p></li>
                                                                        </ul>
                                                                        <h5 className='text-mcolor'>Required Qualifications</h5>
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'>
                                                                                <i className='bi bi-cpu-fill'></i>
                                                                                <p>Bachelor's degree in Electrical Engineering, Computer Engineering, or related field (Master's or PhD preferred)</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i> <p>At Least  8+ years of experience in SoC architecture with specific focus on high-speed interconnect technologies </p></li>
                                                                            <li className='list-group-item'>
                                                                                <i className='bi bi-cpu-fill'></i>
                                                                                <p>Deep technical knowledge of PCIe (Gen 4/5/6) and CXL specifications and implementations </p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i><p>Extensive experience designing and implementing Network-on-Chip architectures </p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i><p>Proficiency in SystemVerilog and UVM for architecture modeling and verification </p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i><p>Strong understanding of SoC performance analysis and optimization techniques </p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i><p>Experience with cache coherency protocols and memory subsystem design</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i><p>Demonstrated ability to lead complex architectural decisions and trade-offs</p></li>
                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                    <button type="button" className="btn btn-sm btn-orange" data-bs-toggle="modal" data-bs-target="#DigitalPE1">Expertise</button>

                                                            <div className="modal fade" id="DigitalPE1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Preferred Expertise</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Experience with PCIe and CXL switch design and implementation is an added advantage </p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Experience with CXL 2.0/3.0 implementation and memory semantics</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Familiarity with emerging interface standards (UCIe, CCIX) </p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Knowledge of AI/ML accelerator integration with high-speed interfaces</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Experience designing multi-die systems using advanced packaging technologies</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Expertise in power optimization techniques for complex SoCs</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Contribution to industry standards committees or publications</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Experience with high-performance computing, data center, or networking applications</p></li>
                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <button type="button" className="btn btn-sm btn-success pull-right" data-bs-toggle="modal" data-bs-target="#jobApplyModal"
                                                            onClick={(e)=>{
                                                                setJobRole("SoC Architect - High Speed Interface Specialist");
                                                            }}
                                                            >Apply Now</button>
                                                            
                                                </div> 
                                            </span>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>                            
                                        <div className='card p-3  mb-3'>
                                            <h5>RTL Designer </h5>                             
                                            <p><span className='text-danger'><i class="bi bi-geo-alt-fill"></i></span> India (Bhubaneswar), Philippines(Manila), Singapore, USA(Milpitas) and Malaysia(Pinang)</p>
                                            <p><span className='text-success'><i class="bi bi-mortarboard-fill"></i></span> BE/ BTech/ MTech/MS/ PhD</p>
                                            <p><span className='text-primary'><i class="bi bi-cpu-fill"></i></span> Electronics, Electrical, Computer Engineering or Computer Science Engineering</p>
                                            <p><span className='text-info'><i class="bi bi-briefcase-fill"></i></span> 2 – 10 Years</p>
                                            <span className='career-jd'>
                                                <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                                    <button type="button" className="btn btn-sm btn-marquee" data-bs-toggle="modal" data-bs-target="#DigitalJD1">Job Description</button>
                                                            <div className="modal fade" id="DigitalJD1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Job Description</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'>
                                                                                <i className='bi bi-cpu-fill'></i>
                                                                                <p>Highly motivated and driven to face the challenging design problems</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i> <p>Architecture and Micro-architecture of complex digital SoCs and mixed signals SoCs</p></li>
                                                                            <li className='list-group-item'>
                                                                                <i className='bi bi-cpu-fill'></i>
                                                                                <p>Be able to communicate with system architects and decipher system requirements and develop architectural specification of the SoC</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i><p>Understanding the future market requirements and develop micro-architecture for the silicon to serve multiple generations of market is a big plus</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i><p>Be able to micro-architect the chip considering low power and high-performance techniques</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i><p>Complete sign-off GDSII working with DV, DFT and PD engineering teams.</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i><p>Be able to comprehend usual service level agreement features – Power, Performance, Area and Customer Schedule, and accordingly plan the development.</p></li>
                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                    <button type="button" className="btn btn-sm btn-orange" data-bs-toggle="modal" data-bs-target="#DigitalPE1">Expertise</button>

                                                            <div className="modal fade" id="DigitalPE1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Preferred Expertise</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Architecting, micro-architecting and designing SOC for embedded processors for storage, IoT, security and compute applications</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p><strong>One or more of Protocol/System knowledge - </strong>PCIe, CXL, UCIe, NVMe, USB4.0, DDR, AXI, CHI, AHB, RISC-V</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Functional safety, power management, coherency, security</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Design Techniques – Low-power, UPF, Clock Domain Crossing (CDC), Booting/Reset Flow</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>SW and/or hardware validation</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Scripting Languages – Shell scripting, Python, TCL, PERL etc.</p></li>
                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <button type="button" className="btn btn-sm btn-success pull-right" data-bs-toggle="modal" data-bs-target="#jobApplyModal"
                                                            onClick={(e)=>{
                                                                setJobRole("RTL Designer");
                                                            }}
                                                            >Apply Now</button>
                                                            
                                                </div> 
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>                            
                                        <div className='card p-3  mb-3'>
                                            <h5>Design Verification (DV) Engineer </h5>                             
                                            <p><span className='text-danger'><i class="bi bi-geo-alt-fill"></i></span> India (Bhubaneswar), Philippines(Manila), Singapore, USA(Milpitas) and Malaysia(Pinang)</p>
                                            <p><span className='text-success'><i class="bi bi-mortarboard-fill"></i></span> BE/ BTech/ MTech/MS/ PhD</p>
                                            <p><span className='text-primary'><i class="bi bi-cpu-fill"></i></span> Electronics, Electrical, Computer Engineering or Computer Science Engineering</p>
                                            <p><span className='text-info'><i class="bi bi-briefcase-fill"></i></span> 5 – 10 Years</p>
                                            
                                            <span className=''>
                                                <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                                    <button type="button" className="btn btn-sm btn-marquee" data-bs-toggle="modal" data-bs-target="#DigitalJD2">Job Description</button>

                                                            <div className="modal fade" id="DigitalJD2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Job Description</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Highly motivated and driven to face the challenging SoC verification</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Development of Complex Pre-Silicon Verification environments for simulation and Emulation platform</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Developing test cases from scratch, coverage plan,</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Work directly with hardware architects, logic designers, IP providers, Physical implementers and Platform validators to influence overall SoC and system design</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Developing System C/ C++ models</p></li>
                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    <button type="button" className="btn btn-sm btn-orange" data-bs-toggle="modal" data-bs-target="#DigitalPE2">Expertise</button>

                                                            <div className="modal fade" id="DigitalPE2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Preferred Expertise</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Verifying SOC for embedded processors for storage, IoT, security and compute applications</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Functional safety, power management, coherency, security</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>IP/SoC Verification, ASIC Verification, CPU (Multiple) Verification</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Formal Verification</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>One or more of Protocol/System knowledge - PCIe, NVMe, USB4.0, DDR, AXI, CHI, AHB, MIPI, RISC-V</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Verification for CDC, Power, Booting/Reset</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>SystemVerilog/UVM environment</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Scripting Languages – Shell scripting, Python, TCL, PERL etc.</p></li>
                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button type="button" className="btn btn-sm btn-success pull-right" data-bs-toggle="modal" data-bs-target="#jobApplyModal"
                                                            onClick={(e)=>{
                                                                setJobRole("Design Verification (DV) Engineer");
                                                            }}
                                                            >Apply Now</button>
                                                </div> 
                                            </span>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>                            
                                        <div className='card p-3 mb-3'>
                                            <h5>Physical Design Engineer</h5>                             
                                            <p><span className='text-danger'><i class="bi bi-geo-alt-fill"></i></span> India (Bhubaneswar), Philippines (Manila)</p>
                                            <p><span className='text-success'><i class="bi bi-mortarboard-fill"></i></span> BE / BTech / MTech / MS / PhD</p>
                                            <p><span className='text-primary'><i class="bi bi-cpu-fill"></i></span> Electronics, Electrical, Computer Engineering or Computer Science Engineering</p>
                                            <p><span className='text-info'><i class="bi bi-briefcase-fill"></i></span> 2 – 10 Years</p>
                                            
                                            <span className=''>
                                                <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                                    <button type="button" className="btn btn-sm btn-marquee" data-bs-toggle="modal" data-bs-target="#DigitalJD3">Job Description</button>

                                                        <div className="modal fade" id="DigitalJD3" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                            <div className="modal-dialog modal-lg">
                                                                <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="">Job Description</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>Implement netlist-to-GDSII that involves all aspects of physical design – full chip floorplan, global routing for clock/reset/power, timing optimization, PnR, static timing analysis and timing closure, signal and power integrity analyses, and physical verification.</p>
                                                                        </li>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>Work with RTL, analog and DFT design teams, as required, to comprehend the physical design issue and implement solution.</p>
                                                                        </li>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>Work creatively and productively in order to develop a set of scripts/methodologies in order to best utilize the EDA tools and servers.</p>
                                                                        </li>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>Develop tcl/shell/perl/python scripts to automate various processes of the Physical Design Flow</p>
                                                                        </li>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>Comprehend usual service level agreement features – Performance, Area, Schedule and Cost, and accordingly plan the development.</p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <button type="button" className="btn btn-sm btn-orange" data-bs-toggle="modal" data-bs-target="#DigitalPE3">Expertise</button>

                                                        <div className="modal fade" id="DigitalPE3" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                            <div className="modal-dialog modal-lg">
                                                                <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="">Preferred Expertise</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>Setting up library and PDK environment for projects</p>
                                                                        </li>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>Chip level and block level Floor planning and IO placement</p>
                                                                        </li>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>Place-and-Route using industry standard tools</p>
                                                                        </li>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>Build Clock and Reset Tree for global signals as per requirement</p>
                                                                        </li>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>Achieve clean routing</p>
                                                                        </li>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>RC Extraction</p>
                                                                        </li>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>Static Timing Analysis in multi-mode multi-corner</p>
                                                                        </li>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>Physical Verification (LVS/DRC/ERC/EMIR) using industry standard tools</p>
                                                                        </li>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>Power/IR analysis</p>
                                                                        </li>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>Logic Synthesis and Logical Equivalence Check</p>
                                                                        </li>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>Power user of EDA tools from Synopsys (ICC2/DC/PT/STAR-RC), Cadence (EDI/Innovus/Voltus).</p>
                                                                        </li>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>Experience in Global signals and Power Distribution, P&R, Timing closure, RC Extraction, and verification on advanced technology nodes</p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*<button type="button" className="btn btn-sm btn-success" data-bs-toggle="modal" data-bs-target="#DigitalE3">Experience</button>

                                                        <div className="modal fade" id="DigitalE3" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                            <div className="modal-dialog modal-lg">
                                                                <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="">Experience</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>2+years in both planar up to 22nm and finfet technology nodes up to 5nm.</p>
                                                                        </li>
                                                                        
                                                                    </ul>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>*/}
                                                        <button type="button" className="btn btn-sm btn-success pull-right" data-bs-toggle="modal" data-bs-target="#jobApplyModal"
                                                            onClick={(e)=>{
                                                                setJobRole("Physical Design Engineer");
                                                            }}
                                                            >Apply Now</button>
                                                </div> 
                                            </span>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>                            
                                        <div className='card p-3  mb-3'>
                                            <h5>DFT Engineer Lead </h5>                             
                                            <p><span className='text-danger'><i class="bi bi-geo-alt-fill"></i></span> India (Bhubaneswar / Bangalore), Philippines (Manila), Singapore and Malaysia(Pinang)</p>
                                            <p><span className='text-success'><i class="bi bi-mortarboard-fill"></i></span> BE/ BTech/ MTech/MS/ PhD</p>
                                            <p><span className='text-primary'><i class="bi bi-cpu-fill"></i></span> Electronics, Electrical, Computer Engineering or Computer Science Engineering</p>
                                            <p><span className='text-info'><i class="bi bi-briefcase-fill"></i></span> 8+ Years</p>
                                            
                                            <span className=''>
                                                <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                                    <button type="button" className="btn btn-sm btn-marquee" data-bs-toggle="modal" data-bs-target="#DigitalJD4">Job Description</button>

                                                            <div className="modal fade" id="DigitalJD4" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Job Description</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Highly motivated and driven to face challenging design and debug problems</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>In-depth knowledge and hands-on experience in scan insertion, ATPG, coverage analysis, and Transition delay test coverage analysis.</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Analyse the design and propose the best compression technique.</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Work with front-end team, DFT and cross-functional teams to provide the solutions and make sure DFT DRCs are fixed</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Generating high-quality manufacturing ATPG test patterns for (SAF) stuck-at, transition fault (TDF), Path Delay fault (PDF) models and through the use of on-chip test compression techniques.</p></li>
                                                                            
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>In-depth knowledge and hands-on experience in Scan insertion and validation, BIST, LBIST, MBIST insertion and verification, ATPG, IP tests and Pattern Validation w/wo Timing, DFT mode timing analysis, and sign-off.</p>
                                                                            </li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Hands-on experience in various DFT aspects like Scan insertion, MBIST and Boundary Scan, ATPG, Pattern validation at block level as well as Full-chip level</p>
                                                                            </li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p><strong>Tessent DFT expertise –</strong> methodology and flow development</p>
                                                                            </li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Working experience in Synopsis TetraMax/DFTMax and Cadence Encounter Test is desired.</p>
                                                                            </li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Scripting Languages – Shell scripting, Python, TCL, PERL etc.</p>
                                                                            </li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Be able to work and support with Test Engineers for post-silicon tasks.</p>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <button type="button" className="btn btn-sm btn-success pull-right" data-bs-toggle="modal" data-bs-target="#jobApplyModal"
                                                            onClick={(e)=>{
                                                                setJobRole("DFT Engineer Lead ");
                                                            }}
                                                            >Apply Now</button>
                                                </div> 
                                            </span>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>                            
                                        <div className='card p-3  mb-3'>
                                            <h5>SystemC Modeling Engineer</h5>                             
                                            <p><span className='text-danger'><i class="bi bi-geo-alt-fill"></i></span> India (Bhubaneswar), Philippines (Manila)</p>
                                            <p><span className='text-success'><i class="bi bi-mortarboard-fill"></i></span> Candidate should possess Degree in Computer Science, Computer Engineering, Electronics Engineering or a related field</p>
                                            <p className='d-none'><span className='text-primary'><i class="bi bi-cpu-fill"></i></span> Electronics, Electrical, Computer Engineering or Computer Science Engineering</p>
                                            <p><span className='text-info'><i class="bi bi-briefcase-fill"></i></span> 5+ Years</p>
                                            
                                            <span className=''>
                                                <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                                    <button type="button" className="btn btn-sm btn-marquee" data-bs-toggle="modal" data-bs-target="#DigitalJD5">Job Description</button>

                                                            <div className="modal fade" id="DigitalJD5" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Job Description</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Responsible for the design, development, and verification of SystemC models for digital logic circuits and systems</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Creates and maintains detailed design specifications, developing efficient and accurate SystemC models, and performing functional and timing simulation and verification</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Collaborate with other engineers to understand requirements and design constraints</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Write and maintain documentation for models and simulation results</p></li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Debug and troubleshoot model issues</p></li>
                                                                            
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Participate in code reviews and contribute to the development of best practices</p>
                                                                            </li>
                                                                            <li className='list-group-item'><i className='bi bi-cpu-fill'></i>
                                                                                <p>Continuously improve the capabilities and efficiency of the models</p>
                                                                            </li>
                                                                            
                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <button type="button" className="btn btn-sm btn-orange" data-bs-toggle="modal" data-bs-target="#DigitalPE5">Expertise</button>

                                                        <div className="modal fade" id="DigitalPE5" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                            <div className="modal-dialog modal-lg">
                                                                <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="">Preferred Expertise</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>Proficient in C++ and object-oriented programming</p>
                                                                        </li>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>Experience with SystemC modeling and simulation</p>
                                                                        </li>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>Strong understanding of digital logic and system-on-chip (SoC) design</p>
                                                                        </li>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>Excellent interpersonal and analytical skills with the ability to work independently</p>
                                                                        </li>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>Must possess good interpersonal skills and can work between multiple departments in carrying out this position’s duties</p>
                                                                        </li>
                                                                        <li className='list-group-item'>
                                                                            <i className='bi bi-cpu-fill'></i>
                                                                            <p>Knowledge of RTL design (Verilog/VHDL) is a plus</p>
                                                                        </li>
                                                                        
                                                                    </ul>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                            
                                                            <button type="button" className="btn btn-sm btn-success pull-right" data-bs-toggle="modal" data-bs-target="#jobApplyModal"
                                                            onClick={(e)=>{
                                                                setJobRole("SystemC Modeling Engineer");
                                                            }}
                                                            >Apply Now</button>
                                                </div> 
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabindex="0">
                                <div className='row p-5'>
                                    <div className='col-md-12'>
                                        <p>There are currently no open positions in your selected business unit</p>
                                        <p>Be up to date with our job opportunities thru our social media channels or click the apply now button to send your profile to our Talent Acquisition Team.</p>
                                    </div>
                                    <div className='col-md-12 text-center mt-3'>
                                        <button type="button" className="btn btn-sm btn-success" data-bs-toggle="modal" data-bs-target="#jobApplyModal"
                                                onClick={(e)=>{
                                                    setJobRole("Analog Business Unit");
                                                }}
                                                >Apply Now</button>
                                    </div>
                                    {/*
                                    <div className='col-md-12'>                            
                                        <div className='card p-3'>
                                            <h5>Analog Circuit Engineer</h5>                             
                                            <p><span className='text-danger'><i class="bi bi-geo-alt-fill"></i></span> India (Bhubaneswar), Philippines(Manila)</p>
                                            <p><span className='text-success'><i class="bi bi-mortarboard-fill"></i></span> BE / BTech / MTech / MS / PhD</p>
                                            <p><span className='text-primary'><i class="bi bi-cpu-fill"></i></span> Electronics, Electrical</p>
                                            <p><span className='text-info'><i class="bi bi-briefcase-fill"></i></span> 2 – 10 Years</p>
                                            <span className=''>
                                                <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                                    <button type="button" className="btn btn-sm btn-marquee" data-bs-toggle="modal" data-bs-target="#AnalogJD1">Job Description</button>

                                                            <div className="modal fade" id="AnalogJD1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Job Description</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Highly motivated and driven to face the challenging activities</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Bringing-up solutions from specification – define, architect and design.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Be accountable for each and every aspect of design from start to finish.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Understanding and providing indications to the layout team</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Should be able work with lab engineers during silicon debug and characterization</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Prepare necessary documentation and prepare datasheets and analyse competitor’s datasheet as well.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Working knowledge & Theoretical background of Analog mixed signal circuit design</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Good understanding of deep sub-micron and DFM issues.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Proactive and always looking out of improvements both  as an individual and at a team level</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Be aware of layout issues and able to guide the layout engineers</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>A good understanding of device theory of FinFET, MOSFETs, BJTs, like short channel effects, ESD, Latch up</p>
																			</li>
                                                                            
                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    <button type="button" className="btn btn-sm btn-orange" data-bs-toggle="modal" data-bs-target="#AnalogJD2">Expertise</button>

                                                            <div className="modal fade" id="AnalogJD2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Preferred Expertise</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Linear and Switching regulators, SerDes, LVDS, DDR Phy, PLL and analog building blocks amplifiers, comparator, oscillator, voltage, current reference circuits, ADC (SAR/ Pipeline), DAC, Memory, GPIO</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Experience in high voltage BCDMOS for power management and lower nodes FinFet for high-speed design.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Custom Design, Standard Cell</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Thorough working knowledge of Circuit design EDA tools using Cadence Virtuoso suite, fast spice simulators, co-sim verilogA modelling is a plus</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Sound knowledge in running PVT corner, Montecarlo and PEX simulation using ADE-XL</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Scripting Languages – SKILL, Shell scripting, Python, TCL, PERL etc.</p>
																			</li>
                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <button type="button" className="btn btn-sm btn-success pull-right" data-bs-toggle="modal" data-bs-target="#jobApplyModal"
                                                            onClick={(e)=>{
                                                                setJobRole("Analog Circuit Engineer");
                                                            }}
                                                            >Apply Now</button>
                                                </div> 
                                            </span>
                                        </div>
                                    </div>

                                    <div className='col-md-6 '>                           
                                        <div className='card d-none'>
                                            <div className='card-body'>
                                            <h5>Analog Layout Engineer</h5>                             
                                                <p><span className='text-danger'><i class="bi bi-geo-alt-fill"></i></span> India (Bhubaneswar), Philippines(Manila)</p>
                                                <p><span className='text-success'><i class="bi bi-mortarboard-fill"></i></span> BE/ BTech/ MTech/MS/ PhD</p>
                                                <p><span className='text-primary'><i class="bi bi-cpu-fill"></i></span> Electronics, Electrical</p>
                                                <p><span className='text-info'><i class="bi bi-briefcase-fill"></i></span> 5 – 10 Years</p>
                                                
                                                <span className=''>
                                                    <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                                        <button type="button" className="btn btn-sm btn-marquee" data-bs-toggle="modal" data-bs-target="#AnalogJD2">Job Description</button>

                                                                <div className="modal fade" id="AnalogJD2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                    <div className="modal-dialog modal-lg">
                                                                        <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title" id="">Job Description</h5>
                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                                <li className='list-group-item'>
                                                                                    <i className='bi bi-cpu-fill'></i>
                                                                                    <p>Demonstrate leadership skills in planning, area/time estimation, scheduling, delegation and execution to meet project schedule/milestones in multiple project environments.</p>
                                                                                </li>
                                                                                <li className='list-group-item'>
                                                                                    <i className='bi bi-cpu-fill'></i>
                                                                                    <p>Layout design of Analog, Mixed-Signal / RF layout blocks right from floorplan to verification to closure</p>
                                                                                </li>
                                                                                <li className='list-group-item'>
                                                                                    <i className='bi bi-cpu-fill'></i>
                                                                                    <p>Knowledge of basic circuits, matching constraints, design-driven constraints expected</p>
                                                                                </li>
                                                                                <li className='list-group-item'>
                                                                                    <i className='bi bi-cpu-fill'></i>
                                                                                    <p>Analog and mixed signal layout mask designing</p>
                                                                                </li>
                                                                                <li className='list-group-item'>
                                                                                    <i className='bi bi-cpu-fill'></i>
                                                                                    <p>Exposure to place, route and integrate digital partitions in custom analog blocks</p>
                                                                                </li>
                                                                                <li className='list-group-item'>
                                                                                    <i className='bi bi-cpu-fill'></i>
                                                                                    <p>Exposure to LVS , DRC, antenna, rv, density, latch up & ESD violations.</p>
                                                                                </li>
                                                                                <li className='list-group-item'>
                                                                                    <i className='bi bi-cpu-fill'></i>
                                                                                    <p>Excellent hands-on Debug Skill is a must.</p>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        <button type="button" className="btn btn-sm btn-orange" data-bs-toggle="modal" data-bs-target="#AnalogPE2">Expertise</button>

                                                                <div className="modal fade" id="AnalogPE2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                    <div className="modal-dialog modal-lg">
                                                                        <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title" id="">Preferred Expertise</h5>
                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                                <li className='list-group-item'>
                                                                                    <i className='bi bi-cpu-fill'></i>
                                                                                    <p>Designed layout of custom analog blocks with digital, analog device design, designs of Data Converters, Timing Circuits, Sensors and high current applications like DC-DC Converters and LDOs</p>
                                                                                </li>
                                                                                <li className='list-group-item'>
                                                                                    <i className='bi bi-cpu-fill'></i>
                                                                                    <p>Linear and Switching regulators, SerDes, LVDS, DDR Phy, PLL and analog building blocks amplifiers, comparator, oscillator, voltage, current reference circuits, Memory, GPIO</p>
                                                                                </li>
                                                                                <li className='list-group-item'>
                                                                                    <i className='bi bi-cpu-fill'></i>
                                                                                    <p>Experience in high voltage BCDMOS for power management and lower nodes FinFet for high-speed design.</p>
                                                                                </li>
                                                                                <li className='list-group-item'>
                                                                                    <i className='bi bi-cpu-fill'></i>
                                                                                    <p>Custom Design, Standard Cell</p>
                                                                                </li>
                                                                                <li className='list-group-item'>
                                                                                    <i className='bi bi-cpu-fill'></i>
                                                                                    <p>Proficiency with industry-standard layout and verification tools in a Linux environment (Cadence and Mentors tools)</p>
                                                                                </li>
                                                                                <li className='list-group-item'>
                                                                                    <i className='bi bi-cpu-fill'></i>
                                                                                    <p>Scripting Languages – Shell scripting, Python, TCL, PERL etc.</p>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <button type="button" className="btn btn-sm btn-success pull-right" data-bs-toggle="modal" data-bs-target="#jobApplyModal"
                                                            onClick={(e)=>{
                                                                setJobRole("Analog Layout Engineer");
                                                            }}
                                                            >Apply Now</button>
                                                    </div> 
                                                </span>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    */}
                                </div>
                            </div>
                            <div className="tab-pane fade" id="v-pills-disabled" role="tabpanel" aria-labelledby="v-pills-disabled-tab" tabindex="0">

                                <div className='row'>
                                    <div className='col-md-6'>                            
                                        <div className='card p-3 mb-3'>
                                            <h5>FPGA Firmware Engineer</h5>                             
                                            <p><span className='text-danger'><i class="bi bi-geo-alt-fill"></i></span> India (Bhubaneswar), Philippines(Manila), Singapore , USA(Milpitas) and Malaysia(Pinang)</p>
                                            <p><span className='text-success'><i class="bi bi-mortarboard-fill"></i></span> Candidate should possess Degree in Computer Science, Engineering or a related field</p>
                                            <p className='d-none'><span className='text-primary'><i class="bi bi-cpu-fill"></i></span> Electronics, Electrical, Computer Engineering or Computer Science Engineering</p>
                                            <p><span className='text-info'><i class="bi bi-briefcase-fill"></i></span> 5 – 10 Years</p>
                                            
                                            <span className=''>
                                                <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                                    <button type="button" className="btn btn-sm btn-marquee" data-bs-toggle="modal" data-bs-target="#SoftwareJD1">Job Description</button>

                                                            <div className="modal fade" id="SoftwareJD1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Job Description</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Develop detailed specifications based on requirements and implement FPGA designs in accordance with those defined requirements and/or specifications.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Collaborate with other members of the design team to evaluate possible alternatives during the development process and determine the best approach based on performance, cost, and resources.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Perform simulation activities including timing analysis, behavioural, and functional simulations.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Document ideas, designs, specifications, and instructions to comply with client standards.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Mentor junior members of the business unit to promote design team abilities and establish good working practices.</p>
																			</li>
                                                                            
                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    <button type="button" className="btn btn-sm btn-orange" data-bs-toggle="modal" data-bs-target="#SoftwareEP1">Expertise</button>

                                                            <div className="modal fade" id="SoftwareEP1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Preferred Expertise</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>5+ years FPGA design, FPGA firmware or related work experience.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Excellent interpersonal and analytical skills with the ability to work independently, and create customer-facing applications</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Must possess good interpersonal skills and have the ability to work between multiple departments in carrying out this position’s duties.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>5+ years of direct hands-on with IP integration and/or HLS based implementation for resources and timing optimization</p>
																			</li>
                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <button type="button" className="btn btn-sm btn-success pull-right" data-bs-toggle="modal" data-bs-target="#jobApplyModal"
                                                            onClick={(e)=>{
                                                                setJobRole("FPGA Firmware Engineer");
                                                            }}
                                                            >Apply Now</button>
                                                </div> 
                                            </span>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>                            
                                        <div className='card p-3 mb-3'>
                                            <h5>Senior Software Engineers</h5>                             
                                            <p><span className='text-danger'><i class="bi bi-geo-alt-fill"></i></span> India (Bhubaneswar), Philippines(Manila), Singapore , USA(Milpitas) and Malaysia(Pinang)</p>
                                            <p><span className='text-success'><i class="bi bi-mortarboard-fill"></i></span> Candidate should possess Degree in Computer Science, Engineering or a related field</p>
                                            <p className='d-none'><span className='text-primary'><i class="bi bi-cpu-fill"></i></span> Electronics, Electrical, Computer Engineering or Computer Science Engineering</p>
                                            <p><span className='text-info'><i class="bi bi-briefcase-fill"></i></span> 5 – 10 Years</p>
                                            
                                            <span className=''>
                                                <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                                    <button type="button" className="btn btn-sm btn-marquee" data-bs-toggle="modal" data-bs-target="#SoftwareJD2">Job Description</button>

                                                            <div className="modal fade" id="SoftwareJD2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Job Description</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Strong problem-solving skills and ability to work with autonomy</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Excellent knowledge of computer architecture of embedded processors, digital and emulation platform</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Understanding of the efficient implementation of high-performance algorithms</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Expertise in full software development lifecycle from requirements analysis to design, development, testing and implementation</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Debugging, and optimizing software on embedded processor</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Strong understanding of version control systems (GIT, SVN etc.)</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Understanding of Neural Networks / Deep Learning in areas of vision, speech</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Having solid experience in software development across the full stack (front-end, back-end, using React.js, Next.js, Node.js, Mongo DB and AWS)</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Good grasp of MongoDB’s aggregation framework, knowledge in maintaining AWS Instances like EC2 and SES</p>
																			</li>

                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    <button type="button" className="btn btn-sm btn-orange" data-bs-toggle="modal" data-bs-target="#SoftwarePE2">Expertise</button>

                                                            <div className="modal fade" id="SoftwarePE2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Preferred Expertise</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Proficient in C/C++ programming</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>System C programming, knowledge of Python, scripting languages</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>C-Simulation (C/C++ Testbench) or SW Emulation (OpenCL Testbench)</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Linux OS – Scripting, Make file</p>
																			</li>
                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <button type="button" className="btn btn-sm btn-success pull-right" data-bs-toggle="modal" data-bs-target="#jobApplyModal"
                                                            onClick={(e)=>{
                                                                setJobRole("Senior Software Engineers");
                                                            }}
                                                            >Apply Now</button>
                                                </div> 
                                            </span>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>                            
                                        <div className='card p-3 mb-3'>
                                            <h5>FPGA High-level Synthesis Engineer</h5>                             
                                            <p><span className='text-danger'><i class="bi bi-geo-alt-fill"></i></span> India (Bhubaneswar), Philippines (Manila) and Malaysia(Pinang)</p>
                                            <p><span className='text-success'><i class="bi bi-mortarboard-fill"></i></span> Candidate should possess Degree in Computer Science, Computer Engineering, Electronics Engineering or a related field</p>
                                            <p className='d-none'><span className='text-primary'><i class="bi bi-cpu-fill"></i></span> Electronics, Electrical, Computer Engineering or Computer Science Engineering</p>
                                            <p><span className='text-info'><i class="bi bi-briefcase-fill"></i></span> 5 – 7 Years</p>
                                            
                                            <span className=''>
                                                <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                                    <button type="button" className="btn btn-sm btn-marquee" data-bs-toggle="modal" data-bs-target="#SoftwareJD3">Job Description</button>

                                                            <div className="modal fade" id="SoftwareJD3" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Job Description</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Develops detailed specifications based on requirements and implement FPGA designs in accordance with those defined requirements and/or specifications.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Implements and tests C/C++ based HW accelerator kernels using High-Level Synthesis Methodology</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Implements and tests C/C++ and OpenCL host codes targeting x86-based and ARM-based hosts</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Performs software emulation or C Simulation testing, hardware emulation or C/RTL Co-simulation testing, and actual hardware evaluation of HW accelerators</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Collaborates with other members of the design team to evaluate possible alternatives during the development process and determine the best approach based on performance, cost, and resources</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Performs simulation activities including timing analysis, behavioural, and functional simulations</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Documents ideas, designs, specifications, and instructions to comply with client standards</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Mentors junior members of the business unit to promote design team abilities and establish good working practices</p>
																			</li>

                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    <button type="button" className="btn btn-sm btn-orange" data-bs-toggle="modal" data-bs-target="#SoftwarePE3">Expertise</button>

                                                            <div className="modal fade" id="SoftwarePE3" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Preferred Expertise</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>5+ years FPGA prototyping, FPGA board bring-up or related work experience</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>5+ years of direct hands-on experience AMD-Xilinx Vivado or Vitis Toolchain, or similar toolchain</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Proficient in C/C++/object-oriented design</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Excellent interpersonal and analytical skills with the ability to work independently, and create customer-facing applications</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Must possess good interpersonal skills and can work between multiple departments in carrying out this position’s duties</p>
																			</li>
                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <button type="button" className="btn btn-sm btn-success pull-right" data-bs-toggle="modal" data-bs-target="#jobApplyModal"
                                                            onClick={(e)=>{
                                                                setJobRole("FPGA High-level Synthesis Engineer");
                                                            }}
                                                            >Apply Now</button>
                                                </div> 
                                            </span>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>                            
                                        <div className='card p-3 mb-3'>
                                            <h5>Quality Assurance Engineer</h5>                             
                                            <p><span className='text-danger'><i class="bi bi-geo-alt-fill"></i></span> Philippines (Manila)</p>
                                            <p><span className='text-success'><i class="bi bi-mortarboard-fill"></i></span> Candidate should possess Degree in Computer Science, Computer Engineering, Electronics Engineering, or a related field</p>
                                            <p className='d-none'><span className='text-primary'><i class="bi bi-cpu-fill"></i></span> Electronics, Electrical, Computer Engineering or Computer Science Engineering</p>
                                            <p><span className='text-info'><i class="bi bi-briefcase-fill"></i></span> 3 – 5 Years</p>
                                            
                                            <span className=''>
                                                <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                                    <button type="button" className="btn btn-sm btn-marquee" data-bs-toggle="modal" data-bs-target="#SoftwareJD4">Job Description</button>

                                                            <div className="modal fade" id="SoftwareJD4" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Job Description</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Review requirements, specifications, and technical design documents to provide timely and meaningful feedback</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Create detailed, comprehensive, and well-structured test plans and test cases</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Estimate, prioritize, plan and coordinate testing activities</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Design, develop and execute automation scripts</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Identify, record, document thoroughly and track bugs</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Perform thorough regression testing when bugs are resolved</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Develop and apply testing processes for new and existing products to meet client needs</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Liaise with internal teams (e.g. developers and product managers) to identify system requirements</p>
																			</li>

                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Monitor debugging process results</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Investigate the causes of non-conforming software and train users to implement solutions</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Track quality assurance metrics, like defect densities and open defect counts</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Stay up-to-date with new testing tools and test strategies</p>
																			</li>

                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    <button type="button" className="btn btn-sm btn-orange" data-bs-toggle="modal" data-bs-target="#SoftwarePE4">Expertise</button>

                                                            <div className="modal fade" id="SoftwarePE4" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Preferred Expertise</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Proven work experience in software development</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Proven work experience in software quality assurance</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Strong knowledge of software QA methodologies, tools, and processes</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Experience in writing clear, concise and comprehensive test plans and test cases</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Hands-on experience with both white box and black box testing</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Hands-on experience with automated testing tools</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Experience working in an Agile/Scrum development process</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Experience with performance and/or security testing is a plus</p>
																			</li>
                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <button type="button" className="btn btn-sm btn-success pull-right" data-bs-toggle="modal" data-bs-target="#jobApplyModal"
                                                            onClick={(e)=>{
                                                                setJobRole("Quality Assurance Engineer");
                                                            }}
                                                            >Apply Now</button>
                                                </div> 
                                            </span>
                                        </div>
                                    </div>

                                    


                                </div>
                            </div>


                            <div className="tab-pane fade" id="v-pills-operation" role="tabpanel" aria-labelledby="v-pills-operation-tab" tabindex="0">
                                <div className='row p-5'>
                                    <div className='col-md-12 text-center pb-3'>
                                            <h4 className='text-mcolor'>IT | HR | ADMIN | FINANCE</h4>
                                    </div>
                                    <div className='col-md-12'>
                                        <p>Our corporate functions work as global teams. This encourages a unique and diverse work environment to practice their knowledge and skills.</p>
                                    </div>
                                    <div className='col-md-12 text-center mt-3'>
                                        <button type="button" className="btn btn-sm btn-success" data-bs-toggle="modal" data-bs-target="#jobApplyModal"
                                                onClick={(e)=>{
                                                    setJobRole("Operational Excellence Unit");
                                                }}
                                                >Apply Now</button>
                                    </div>
                                </div>
                                {/*
                                <div className='row'>
                                    <div className='col-md-6'>                            
                                        <div className='card p-3 mb-3'>
                                            <h5>Technical Writer</h5>                             
                                            <p><span className='text-danger'><i class="bi bi-geo-alt-fill"></i></span> Philippines(Ortigas), India (Bhubaneswar)</p>
                                            <p><span className='text-success'><i class="bi bi-mortarboard-fill"></i></span> Computer Science/Information Technology, Business Studies / Administration/  Management or equivalent</p>
                                            <p className='d-none'><span className='text-primary'><i class="bi bi-cpu-fill"></i></span> Electronics, Electrical, Computer Engineering or Computer Science Engineering</p>
                                            <p><span className='text-info'><i class="bi bi-briefcase-fill"></i></span> 5 – 10 Years</p>
                                            
                                            <span className=''>
                                                <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                                    <button type="button" className="btn btn-sm btn-marquee" data-bs-toggle="modal" data-bs-target="#OperationJD1">Job Description</button>

                                                            <div className="modal fade" id="OperationJD1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Job Description</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Responsible for documentation and company communications through various channels including writing technical papers of company IPs through the help of the engineering team</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Partner with the engineering team to create documents such as User Manuals, Handbooks, Release Notes, and PPT-based training rich in images and technical descriptions. The primary focus will be on quick and easy understanding by the end-user of this material.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Write, format, edit, review, and proof procedural and technical information for technical and nontechnical users Able to quickly grasp complex technical concepts and make them easily understandable in text and pictures</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Writing and reviewing content for websites, social media pages and public communications</p>
																			</li>
                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    <button type="button" className="btn btn-sm btn-orange" data-bs-toggle="modal" data-bs-target="#OperationPE1">Expertise</button>

                                                            <div className="modal fade" id="OperationPE1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Preferred Expertise</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Full understanding of all social media platforms</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Ability to work hands-on with cross-functional teams</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Excellent communication skills, both written and verbal</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Ability to react to change and thrive in a fast-paced start-up environment and ability to meet deadlines.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Ability to write and create succinctly and convey technical concepts and materials clearly.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Strong overall computer and presentation skills</p>
																			</li>
                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <button type="button" className="btn btn-sm btn-success pull-right" data-bs-toggle="modal" data-bs-target="#jobApplyModal"
                                                            onClick={(e)=>{
                                                                setJobRole("Technical Writer");
                                                            }}
                                                            >Apply Now</button>
                                                </div> 
                                            </span>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>                            
                                        <div className='card p-3 mb-3'>
                                            <h5>Administrative Assistant</h5>                             
                                            <p><span className='text-danger'><i class="bi bi-geo-alt-fill"></i></span> Philippines(Manila)</p>
                                            <p><span className='text-success'><i class="bi bi-mortarboard-fill"></i></span> Business Studies/ Administration/  Management or equivalent</p>
                                            <p className='d-none'><span className='text-primary'><i class="bi bi-cpu-fill"></i></span> Electronics, Electrical, Computer Engineering or Computer Science Engineering</p>
                                            <p><span className='text-info'><i class="bi bi-briefcase-fill"></i></span> 5 – 10 Years</p>
                                            
                                            <span className=''>
                                                <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                                    <button type="button" className="btn btn-sm btn-marquee" data-bs-toggle="modal" data-bs-target="#OperationJD2">Job Description</button>

                                                            <div className="modal fade" id="OperationJD2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Job Description</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <h5>Finance</h5>
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Perform End to End Asset Management</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Asset Monitoring</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Process Business Permits Annually (LGU and Brgy)</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Performs Entry in Books of Account</p>
                                                                                <ul>
                                                                                    <li>Cash Disbursement</li>
                                                                                    <li>Collection</li>
                                                                                    <li>Subsidiaries Sales Journal</li>
                                                                                    <li>Journal</li>
                                                                                    <li>Ledger</li>
                                                                                </ul>
																			</li>
                                                                        </ul>
                                                                        <h5>Human Resources</h5>
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>HR- Sourcing Support</p>
                                                                                <ul>
                                                                                    <li>Job posting in MQS Social Media Site</li>
                                                                                    <li>Updating of Recruitment Trackers</li>
                                                                                    <li>Candidate Coordination (Queries and Scheduling)</li>
                                                                                    <li>Interview Facilitation</li>
                                                                                </ul>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Assist in Onboarding of Candidates</p>
                                                                                <ul>
                                                                                    <li>Accounts Enrollment</li>
                                                                                    <li>Orientation</li>
                                                                                </ul>
																			</li>
                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    <button type="button" className="btn btn-sm btn-orange" data-bs-toggle="modal" data-bs-target="#OperationPE2">Expertise</button>

                                                            <div className="modal fade" id="OperationPE2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Preferred Expertise</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Ability to react to change and thrive in a fast-paced start-up environment and ability to meet deadlines.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Has an experience or overview in journal entry is a plus</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Sound knowledge of online and web-based communications</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Adequate knowledge of computer set up and troubleshooting</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Experience managing facilities and security, ensuring cost effective purchasing and office supplies</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Ability to assess and respond to changing office needs</p>
																			</li>
                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <button type="button" className="btn btn-sm btn-success pull-right" data-bs-toggle="modal" data-bs-target="#jobApplyModal"
                                                            onClick={(e)=>{
                                                                setJobRole("Administrative Assistant");
                                                            }}
                                                            >Apply Now</button>
                                                </div> 
                                            </span>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>                            
                                        <div className='card p-3 mb-3'>
                                            <h5>Technical Recruiter</h5>                             
                                            <p><span className='text-danger'><i class="bi bi-geo-alt-fill"></i></span> Philippines(Manila)</p>
                                            <p><span className='text-success'><i class="bi bi-mortarboard-fill"></i></span> Business Studies/ Administration/  Management or equivalent</p>
                                            <p className='d-none'><span className='text-primary'><i class="bi bi-cpu-fill"></i></span> Electronics, Electrical, Computer Engineering or Computer Science Engineering</p>
                                            <p><span className='text-info'><i class="bi bi-briefcase-fill"></i></span> 5 – 10 Years</p>
                                            
                                            <span className=''>
                                                <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                                    <button type="button" className="btn btn-sm btn-marquee" data-bs-toggle="modal" data-bs-target="#OperationJD3">Job Description</button>

                                                            <div className="modal fade" id="OperationJD3" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Job Description</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Lead full life cycle recruiting including developing search strategy, posting/advertising plan, sourcing, initial screening and technical screening scheduling.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Develop and implement offshore market talent engagement strategies to support all Marquee office locations.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Heads the University tie ups / partnership across all Marquee office locations.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Assure the smooth endorsement of onboarding candidates to Human resources.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>To be the champion of Marquee’s employee branding projects</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Assist with engagement activities from time to time.</p>
																			</li>
                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    <button type="button" className="btn btn-sm btn-orange" data-bs-toggle="modal" data-bs-target="#OperationPE3">Expertise</button>

                                                            <div className="modal fade" id="OperationPE3" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Preferred Expertise</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Familiar with US engineering talent market and recruitment</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Experience in offshore recruitment is an advantage.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Ability to react to change and thrive in a fast-paced start-up environment and ability to meet deadlines.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Must have a marketing eye and knowledge in using basic graphic design tools.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Good working proficiency in computer skills and MS Office</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Sound knowledge of full cycle recruiting</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Amenable to work US business hours</p>
																			</li>
                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <button type="button" className="btn btn-sm btn-success pull-right" data-bs-toggle="modal" data-bs-target="#jobApplyModal"
                                                            onClick={(e)=>{
                                                                setJobRole("Technical Recruiter");
                                                            }}
                                                            >Apply Now</button>
                                                </div> 
                                            </span>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>                            
                                        <div className='card p-3'>
                                            <h5>System and Website Administrator</h5>                             
                                            <p><span className='text-danger'><i class="bi bi-geo-alt-fill"></i></span> Philippines(Manila)</p>
                                            <p><span className='text-success'><i class="bi bi-mortarboard-fill"></i></span> Information Technology / Computer Engineering</p>
                                            <p className='d-none'><span className='text-primary'><i class="bi bi-cpu-fill"></i></span> Electronics, Electrical, Computer Engineering or Computer Science Engineering</p>
                                            <p ><span className='text-info'><i class="bi bi-briefcase-fill"></i></span> 5+ Years</p>
                                            
                                            <span className=''>
                                                <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                                    <button type="button" className="btn btn-sm btn-marquee" data-bs-toggle="modal" data-bs-target="#OperationJD4">Job Description</button>

                                                            <div className="modal fade" id="OperationJD4" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Job Description</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <h5>Website Management</h5>
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Creates and maintains internal and external websites in collaboration with web developers.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Reviews web content, links, and design; provides necessary updates and enhancements in a timely manner.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Monitors site security; reports suspected or actual security breaches and denial of service attacks to appropriate staff.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Implements appropriate security measures such as firewalls or message encryption.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Manages internet and/or intranet infrastructure including but not limited to web, file transfer protocol (FTP), mail servers, and news.  </p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Identifies useful site performance metrics; collects, tracks, records, compiles, analyzes, and reports site usage data.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Conducts user testing and use analysis to assess usability and effectiveness of site; recommends improvements based on analysis.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Discusses, analyzes, reviews, and resolves usability issues in conjunction with development teams.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Communicates with site visitors and users regarding site updates, anticipated and unanticipated downtime, and resolution of bugs and outages.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Drafts, documents, and implements backup, recovery, and business continuity plans.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Maintains knowledge and expertise in web design and development; participates in professional conferences, workshops, and groups.</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Performs other related duties as assigned. </p>
																			</li>
                                                                        </ul>
                                                                        <h5>Systems Management</h5>
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Responsible for the maintenance, configuration, and reliable operation of computer systems, network servers, and virtualization</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Install and upgrade computer components and software, manage virtual servers, and integrate automation processes</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Troubleshoot hardware and software errors by running diagnostics, documenting problems and resolutions, prioritizing problems, and assessing the impact of issues</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Provide documentation and technical specifications to IT staff for planning and implementing new or upgrades of IT infrastructure</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Perform or delegate regular backup operations and implement appropriate processes for data protection, disaster recovery, and failover procedures</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Lead desktop and helpdesk support efforts, making sure all desktop applications, workstations, and related equipment problems are resolved in a timely manner with limited disruptions</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Responsible for capacity, storage planning, and database performance</p>
																			</li>
                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    <button type="button" className="btn btn-sm btn-orange" data-bs-toggle="modal" data-bs-target="#OperationPE4">Expertise</button>

                                                            <div className="modal fade" id="OperationPE4" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="">Preferred Expertise</h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <ul className='text-secondary text-justify list-group list-group-flush job-desc'>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>A good understanding of Linux System Administration</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Thorough understanding of Firewall policies and simple to complex network implementation for scalability</p>
																			</li>
                                                                            <li className='list-group-item'>
																				<i className='bi bi-cpu-fill'></i>
                                                                                <p>Scripting Languages Shell, Perl, Python for automation</p>
																			</li>
                                                                        </ul>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <button type="button" className="btn btn-sm btn-success pull-right" data-bs-toggle="modal" data-bs-target="#jobApplyModal"
                                                            onClick={(e)=>{
                                                                setJobRole("System and Website Administrator");
                                                            }}
                                                            >Apply Now</button>
                                                </div> 
                                            </span>
                                        </div>
                                    </div>


                                </div>

                                */}
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </>
  )
}

export default Career